import React from "react"
import { Helmet } from "react-helmet"
import PetrachorLogo from "../../images/petrachor-logo.png"

const SEO = props => {
  const isBrowser = () => typeof window !=="undefined" ;
  const url = isBrowser() && window.location.href;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {/* <!-- Google Tag Manager --> */}
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TDXFJ5M');`}
      </script>
      {/* <!-- End Google Tag Manager --> */}
      <title>{props.title || process.env.SITE_TITLE}</title>
      <meta name="description" content={props.desc || process.env.SITE_DESC} />
      <link rel="canonical" href={url} />
      <meta name="image" content={PetrachorLogo} />
    </Helmet>
  )
}

export default SEO
